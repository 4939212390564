import store from '../store/index';

export const getAddressInfo = async (e: any, isFirst?: any) => {
  return new Promise<any>(async (resolve, reject) => {
    console.log('e:', e);
    let lng = e[0] ? e[0] : e.lng;
    let lat = e[1] ? e[1] : e.lat;
    console.log('lng,lat:', lng, lat);
    let lnglat = [lng, lat];
    console.log('store.state.accessToken:', store.state.accessToken);
    const geocodingUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${store.state.accessToken}`;
    let mapData;
    // 使用 Geocoding API 获取地址信息
    fetch(geocodingUrl)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        // 获取第一个匹配结果的地址信息
        console.log('Clicked at: ' + data.features[0].place_name);
        mapData = [data.features[0].place_name, lnglat];
        resolve(mapData);
      });
  });

  //  return mapData;
};
