import axios from 'axios';
import store from '@/store';

const baseURL = 'https://integrated.afirstsoft.cn/'; // 目标测试地址
const service = axios.create({
  baseURL,
  timeout: 10 * 1000,
  headers: {},
});

//获取购物车链接
export const getMapBoxToken = (data: any) => {
  return service({
    url: `/api/v1/mapbox/getMapBoxToken`,
    method: 'get',
    params: data,
  });
};
