import axios from 'axios';
import store from '@/store';

const baseURL = 'https://api.hitpaw.com/'; // 目标测试地址
const service = axios.create({
  baseURL,
  timeout: 10 * 1000,
  headers: {},
});

//获取Ip
export const getUserIp = (data: any) => {
  return service({
    headers: {
      Authorization: localStorage.getItem('authorization'),
    },
    url: `/app/user-ip`,
    method: 'get',
    params: data,
  });
};
